var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-contest" },
    [
      _c("Breadcrumb", { attrs: { items: _vm.breadcrumbs } }),
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c(
        "v-container",
        [_c("CategoryTitle", { attrs: { category: _vm.category } })],
        1
      ),
      _c(
        "v-container",
        [
          _c("CategoryMainSlider", {
            key: _vm.category.categoryId,
            attrs: {
              categories: _vm.category.children,
              paginationClass: "subcategory"
            }
          })
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: _vm.$vuetify.breakpoints.xsOnly } },
        [
          _c("h3", [_vm._v("Tutti i "), _c("strong", [_vm._v("prodotti")])]),
          _c("ProductListGrid", {
            key: _vm.category.categoryId,
            attrs: {
              parentCategoryId: _vm.category.categoryId,
              hidePromoFilter: true,
              hideSort: true,
              hideFilterString: "Main Term"
            },
            on: { productsCount: _vm.updateCount }
          })
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2" }
      }),
      _c(
        "v-container",
        [
          _c("ebsn-meta", {
            attrs: {
              target: _vm.category,
              path: "category_info.FOOTER_DESCRIPTION"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }